// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
  var msViewportStyle = document.createElement('style')
  msViewportStyle.appendChild(
    document.createTextNode(
      '@-ms-viewport{width:auto!important}'
    )
  )
  document.querySelector('head').appendChild(msViewportStyle)
}

jQuery(document).ready(function () {
  // LazyLoading
  jQuery("img").unveil(500, function () {
    jQuery(this).on('load', function () {
      jQuery(this).removeAttr("data-src");
    });
  });
  jQuery("#nav-opener").click(function(){
    jQuery(this).toggleClass("is-active");
    jQuery("#nav-container").toggleClass("is-active");
  });
  jQuery("#nav-closer").click(function(){
    jQuery("#nav-opener, #nav-container").toggleClass("is-active");
  });
  jQuery("#search-opener").click(function(){
    jQuery(this).toggleClass("is-active");
    jQuery("#search-container").toggleClass("is-active");
  });
  jQuery(".banner > ul").slick({
    arrows: false,
    dots: true
  });
  jQuery(".gallery a").simpleLightbox({
    showCounter: false,
    history: false
  });
  jQuery("select").selectric();
  // Dodá všem iframe elmentům responsivní vlastnost v rámci entryContent (wysiwyg editor)
  jQuery(".entry-content iframe").each(function () {
    jQuery(this).addClass("embed-responsive-item").wrap("<div class=\"embed-responsive embed-responsive-16by9\"></div>");
    jQuery(this).removeAttr("width");
    jQuery(this).removeAttr("height");
  });

  // Dodá všem table elmentům responsivní vlastnost v rámci entryContent (wysiwyg editor)
  jQuery(".entry-content table").each(function () {
    jQuery(this).addClass("table table-responsive");
  });
});
